<template>
    <div class="mainbox">
        <div class="detail_right">
            <div class="detail_right_til elp">{{ mList.newTitle }}</div>
            <div v-html="ImgSizeLimit(mList.newDetail)" class="question_list">
            </div>
        </div>
    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
export default {
    components: {},

    data() {
        return {
            mList: {},
        };
    },
    created() {
        this.id = this.$route.query.newid;
        know.getContentNewPcData({ id: this.id }).then((res) => {
            this.mList = res.data;
        });
        this.getList()
    },
    mounted() {

    },
    methods: {


    },
};
</script>

<style lang="less" scoped>
.mainbox {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;

    .detail_right {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;

        .detail_right_til {
            width: 100%;
            height: 70px;
            text-align: left;
            line-height: 70px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
            font-weight: normal;
            font-size: 20px;
            color: #444444;
            font-weight: bold;
            border-bottom: 1px solid #DFDFDF;
            padding-left: 30px;
            padding-right: 30px;
        }

        .question_list {
            padding: 30px;
            line-height: 1.5;


        }
    }
}

img {
    display: block;
    width: 100%;
    height: 100%;
}
</style>